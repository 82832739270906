// @flow

import React from 'react'
import { Link } from 'gatsby'
import { useIntl } from 'react-intl'

import { linkToSyllabusPost } from 'src/links'

import styles from './SyllabusList.module.scss'

type Props = {|
  subject: string,
  topics: Array<Object>,
|}

const SyllabusList = ({ subject, topics }: Props) => {
  const { locale } = useIntl()

  return (
    <div className={styles.container}>
      <h3>{subject}</h3>
      <ul className={styles.linkList}>
        {topics.map((topic, i) => {
          return (
            <li key={i}>
              <Link
                to={linkToSyllabusPost(locale, topic.slug)}
                className={styles.linkListItem}
              >
                {topic.title}
              </Link>
            </li>
          )
        })}
      </ul>
    </div>
  )
}

export default SyllabusList
