// @flow

import React from 'react'
import { graphql } from 'gatsby'

import { withIntl } from 'src/intl'

import { RESOURCES } from 'src/common/images'
import { createSubjectsAndSubSectionsArray } from 'src/common/utils/'

import {
  Layout,
  Footer,
  Navbar,
  BackgroundImage,
  Heading,
} from 'src/common/components'
import SyllabusList from 'src/components/SyllabusList/components/SyllabusList'
import PageRow from 'src/common/components/PageRow'

import styles from './syllabus-page.module.scss'
import HeadTags from '../components/HeadTags'

type PageContext = {
  locale: string,
  pagePath: string,
}

type Props = {|
  data: Object,
  pageContext: PageContext,
|}

const SyllabusPage = ({ data, pageContext }: Props) => {
  const posts = data.allMarkdownRemark.edges
  const {
    meta: { ogImage, ...otherMeta },
    intro,
  } = data.markdownRemark.frontmatter
  const { footer } = data.footer.frontmatter

  const subjectsAndSubSectionsArray = createSubjectsAndSubSectionsArray(
    posts,
    true
  )

  return (
    <>
      <HeadTags
        pageContext={pageContext}
        ogImage={ogImage || RESOURCES}
        {...otherMeta}
      />
      <Layout>
        {{
          navbar: <Navbar pagePath={pageContext.pagePath} />,
          background: <BackgroundImage />,
          footer: <Footer content={footer} />,
          body: (
            <PageRow firstRow>
              <Heading text={intro.heading} />
              <p className={styles.subHeader}>{intro.description}</p>
              <div className={styles.container}>
                {subjectsAndSubSectionsArray.map((item, i) => {
                  return (
                    <SyllabusList
                      key={i}
                      subject={item.subject}
                      topics={item.SubSections}
                    />
                  )
                })}
              </div>
            </PageRow>
          ),
        }}
      </Layout>
    </>
  )
}

export default withIntl(SyllabusPage)

export const pageQuery = graphql`
  query SyllabusPosts($id: String!, $locale: String!) {
    markdownRemark(id: { eq: $id }) {
      frontmatter {
        ...MetaFragment
        intro {
          heading
          description
        }
      }
    }
    allMarkdownRemark(
      filter: {
        frontmatter: {
          locale: { eq: $locale }
          pageType: { eq: "syllabus-post" }
        }
      }
    ) {
      edges {
        node {
          excerpt
          id
          fields {
            slug
          }
          frontmatter {
            title
            subject
            ageGroup
            examBoard
            isDiscoverable
          }
        }
      }
    }
    ...FooterFragment
  }
`
